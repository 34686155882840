<template>
  <div class="bbr-reports--sales">
    <main-app-bar>
      <template v-slot:title> Users Scheduled for Deletion </template>
    </main-app-bar>

    <div class="mt-7 pb-6">
      <v-row>
        <v-col cols="12">
          <div class="form-container">
            <div class="search-form">
              <v-row no-gutter>
                <v-spacer />

                <v-col cols="10" class="d-flex align-center">
                  <v-text-field
                    class="mr-2"
                    label="Email"
                    v-model="filter.email"
                    @input="searchChange"
                    hide-details
                    clearable
                    outlined
                    solo
                    flat
                  />

                  <v-select
                    class="mr-2"
                    :items="reasons"
                    v-model="filter.reason"
                    label="Reason"
                    clearable
                    hide-details
                    outlined
                    solo
                    flat
                  />

                  <v-menu
                    v-model="deletedAtMenu"
                    :close-on-content-click="false"
                    min-width="290px"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        class="mr-2"
                        v-model="filter.deleted_at"
                        v-on="on"
                        label="Date Requested"
                        clearable
                        hide-details
                        outlined
                        readonly
                        flat
                        solo
                      />
                    </template>
                    <v-date-picker
                      v-model="filter.deleted_at"
                      @input="deletedAtMenu = false"
                      scrollable
                      no-title
                    />
                  </v-menu>

                  <v-select
                    class="mr-2"
                    :items="statuses"
                    v-model="filter.status"
                    label="Delete Status"
                    item-text="label"
                    item-value="id"
                    clearable
                    hide-details
                    outlined
                    solo
                    flat
                  />

                  <v-btn color="primary" text @click="applyFilters">
                    Apply Filters
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>

        <v-col cols="12">
          <list-table
            :loading="loading"
            :list="userLists"
            :meta="meta"
            :links="links"
            @load-more="loadMoreUserLists"
          />
        </v-col>
      </v-row>
    </div>
    <scroll-top bottom fixed right dark fab />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { debounce } from 'lodash'
import { mdiDownload, mdiTune } from '@mdi/js'
import ScrollTop from '@/components/elements/ScrollTop'
import MainAppBar from '@/layouts/shared/MainAppBar'
import ListTable from './components/ListTable'

export default {
  name: 'UserScheduledPage',

  components: {
    MainAppBar,
    ListTable,
    ScrollTop,
  },

  data() {
    return {
      icons: { export: mdiDownload, filter: mdiTune },
      loading: false,

      deletedAtMenu: null,
      statuses: [
        { id: 1, label: 'Pending' },
        { id: 2, label: 'Canceled' },
        { id: 3, label: 'Deleted' },
      ],
      reasons: [
        'I don’t understand how it works',
        'I’m not using it anymore',
        'I don’t understand how it works',
        'I have privacy concerns',
        'I have a duplicate account',
        'I receive too many emails/notifications',
        'Others',
      ],

      openFilter: false,
      filter: {
        email: null,
        reason: null,
        deleted_at: null,
        status: null,
      },
    }
  },

  computed: {
    ...mapState({
      userLists: (state) => state.deletedAccount.list,
      meta: (state) => state.deletedAccount.listMeta,
      links: (state) => state.deletedAccount.listLinks,
    }),
  },

  created() {
    this.fetchUserLists()
  },

  destroyed() {
    this.clearList()
    this.clearListMeta()
  },

  methods: {
    ...mapActions({
      getList: 'deletedAccount/getLists',
    }),

    ...mapMutations({
      clearList: 'deletedAccount/clearList',
      clearListMeta: 'deletedAccount/clearListMeta',
    }),

    searchChange: debounce(function() {
      this.clearList()
      this.fetchUserLists()
    }, 800),

    applyFilters() {
      this.clearList()
      this.fetchUserLists()
    },
    resetFilters() {},

    loadMoreUserLists() {
      if (this.links.next) {
        this.fetchUserLists(this.meta.current_page + 1)
      }
    },

    async fetchUserLists(page = 1) {
      if (this.loading) return

      this.loading = true
      await this.getList({ page, ...this.filter })
      this.loading = false
    },
  },
}
</script>
