<template>
  <v-row>
    <v-col cols="12">
      <v-simple-table>
        <thead>
          <tr>
            <th>User ID</th>
            <th>Email</th>
            <th>Reason</th>
            <th>Details</th>
            <th>Status</th>
            <th width="170px">Requested Date</th>
            <th width="170px">Target Deletion Date</th>
          </tr>
        </thead>

        <tbody
          v-infinite-scroll="loadMore"
          :infinite-scroll-disabled="disableListScroll"
        >
          <tr :key="key" v-for="(row, key) in list">
            <td>{{ row.user_id }}</td>
            <td>{{ row.email }}</td>
            <td>{{ row.reason }}</td>
            <td>{{ row.details }}</td>
            <td>{{ row.status | deletedAccountStatus }}</td>
            <td>{{ row.created_at | formatDate }}</td>
            <td>{{ row.deleted_at | formatDate }}</td>
          </tr>
        </tbody>

        <table-skeleton-loader v-if="loading" column="7" row />
      </v-simple-table>

      <no-list
        v-if="!hasUserPrograms && !loading"
        details="No Users Scheduled for Deletion"
      />

      <div
        v-if="meta.last_page > 1 && inLastPage"
        class="d-flex justify-center mt-6"
      >
        <h1 class="grey--text headline">
          You have reached the bottom of the page
        </h1>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll'
import NoList from '@/components/elements/NoList'
import TableSkeletonLoader from '@/components/elements/loaders/TableSkeleton'

export default {
  name: 'ListTable',

  components: {
    NoList,
    TableSkeletonLoader,
  },

  directives: {
    infiniteScroll,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    list: {
      type: Array,
      default: () => [],
      required: true,
    },

    meta: {
      type: Object,
      required: true,
    },

    links: {
      type: Object,
      required: true,
    },
  },

  computed: {
    inLastPage() {
      return !this.links.next
    },

    hasUserPrograms() {
      return !!this.list.length
    },

    disableListScroll() {
      return this.loading || this.inLastPage
    },
  },

  methods: {
    loadMore() {
      this.$emit('load-more')
    },
  },
}
</script>
